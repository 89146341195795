.crane-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 40px;
}

.username {
  margin: 0 0 0 -4%;
  font-size: 18px;
}

.card-image {
  width: 100vw;
}

.likes {
  background-color: white;
  border-radius: 0 30px 0 0;
  padding: 0 10px 0 8px;
  position: relative;
  margin: -9% 0 0 -1.5%;
}

.basic-info {
  margin: 0 0 1% 0;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
}

.basic-info p {
  margin: 0 0 2% 0;
  padding-left: 10px;
  display: flex;
  text-align: start;
  color: black;
}

.show-more-button {
  font-size: 18px;
  position: relative;
}

.extra-info {
  text-align: start;
  width: 100vw;
}

.extra-info-items {
  margin: -3% 4% -6% 5.5%;
}

.crane-distance {
  font-weight: 500;
  color: #f83600;
  margin: 0 0 0 0;
}

.extra-info button {
  margin: 10% 30% 7% 30%;
  text-align: center;
  font-size: 16px;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.like-icon {
  font-size: 20px;
  color: #f83600;
}

@media only screen and (min-width: 600px) {
  .crane-card {
    display: none;
  }
}

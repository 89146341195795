.crane-card-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 40px;
}

.card-image-profile {
  object-fit: cover;
  width: 100vw;
}

.likes-profile {
  background-color: white;
  border-radius: 0 30px 0 0;
  padding: 0 10px 0 8px;
  position: relative;
  margin: -9% 0 0 -1.5%;
}

.basic-info-profile {
  margin: 0 0 2% 0;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
}

.basic-info-profile p {
  margin: 0 0 2% 0;
  padding-left: 20px;
  display: flex;
  text-align: start;
  color: black;
}

.show-more-button-profile {
  font-size: 18px;
  position: relative;
}
.extra-info-profile {
  text-align: start;
  width: 100vw;
}

.extra-info-items-profile {
  margin: -3% 4% -6% 5.5%;
}

.crane-distance-profile {
  color: #f83600;
  margin: 0 0 0 0;
}

.extra-info-profile button {
  margin: 10% 30% 7% 30%;
  text-align: center;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 16px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.like-icon-profile {
  font-size: 20px;
  color: #f83600;
  padding: 0 8px 0 6px;
}

@media only screen and (min-width: 600px) {
  .crane-card-profile {
    display: none;
  }
}

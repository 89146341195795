@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-items {
  display: flex;
  justify-content: center;
  list-style: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 45px;
  padding-bottom: 11px;
  padding-top: 8px;
  margin-top: 0px;
  margin-left: -35px;
  background-color: #f4f4f4;
  border-top: hidden;
  border-bottom: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
}

.header-icon {
  padding-right: 10px;
  height: 48px;
}

@media only screen and (min-width: 600px) {
  .header-items {
    display: none;
  }
}

.login-p {
  font-size: 32px;
  position: relative;
  margin-top: 15px;
}

.login-form {
  position: relative;
  margin-top: 15px;
}

.login-form input {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin-bottom: 22px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: left;
}

::-webkit-input-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

::-ms-input-placeholder {
  color: #000;
}

::placeholder {
  color: #000;
}

.login-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.register-link {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.register-link:visited {
  color: #000;
  text-decoration: none;
}

.social-icons {
  width: 35px;
}

.social-links-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-left: -43px;
}

@media only screen and (min-width: 600px) {
  .login-p {
    display: none;
  }

  .login-form {
    display: none;
  }

  .register-link {
    display: none;
  }
}

.popup-profile {
  position: absolute;
  background-color: white;
  border: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
  height: 40%;
  width: 80%;
  margin: 0 0 0 4%;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
}

.popup-profile:first-child {
  padding-top: 20px;
}

.popup-username {
  font-size: 30px;
  background: -webkit-linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-profile button {
  margin: 5% 0 5% 0;
  text-align: center;
  font-size: 16px;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.google-maps {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
}
.search {
  position: fixed;
  margin: 0 0 0 -3%;
  top: 80px;
  width: 100%;
  max-width: 250px;
  z-index: 10;
}

.search input {
  padding: 6px;
  font-size: 16px;
  width: 70%;
}

.locate {
  position: fixed;
  top: 85px;
  right: 0.5rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
}

@media only screen and (min-width: 600px) {
  .google-maps {
    display: none;
  }

  .search {
    display: none;
  }

  .locate {
    display: none;
  }
}

.crane-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 40px;
}

.username {
  margin: 0 0 0 -4%;
  font-size: 18px;
}

.card-image {
  width: 100vw;
}

.likes {
  background-color: white;
  border-radius: 0 30px 0 0;
  padding: 0 10px 0 8px;
  position: relative;
  margin: -9% 0 0 -1.5%;
}

.basic-info {
  margin: 0 0 1% 0;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
}

.basic-info p {
  margin: 0 0 2% 0;
  padding-left: 10px;
  display: flex;
  text-align: start;
  color: black;
}

.show-more-button {
  font-size: 18px;
  position: relative;
}

.extra-info {
  text-align: start;
  width: 100vw;
}

.extra-info-items {
  margin: -3% 4% -6% 5.5%;
}

.crane-distance {
  font-weight: 500;
  color: #f83600;
  margin: 0 0 0 0;
}

.extra-info button {
  margin: 10% 30% 7% 30%;
  text-align: center;
  font-size: 16px;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.like-icon {
  font-size: 20px;
  color: #f83600;
}

@media only screen and (min-width: 600px) {
  .crane-card {
    display: none;
  }
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 45px;
  padding-top: 15px;
  margin-left: -40px;
  margin-bottom: 0px;
  background-color: #f4f4f4;
  border-top: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
}

.navbar-icons {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 600px) {
  .navbar-links {
    display: none;
  }
}

.filter-sort {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  width: 100vw;
}

.filter-sort button {
  text-align: center;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  margin: 3% 3% 3% 3%;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.filter-sort ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-top: -10px;
  list-style: none;
}
.filter-sort li {
  flex: 1 0 200px;
  box-sizing: border-box;
  font-size: 16px;
}

.links {
  color: black;
  text-decoration: none;
}

.sliders {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10%;
  width: 80vw;
}
.filter-sort p {
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .filter-sort {
    display: none;
  }
}

.alert-error {
  color: red;
  background: #ffe5e5;
  width: 70vw;
  font-size: 22px;
  padding: 6px 16px 6px 16px;
  margin: 5% 10% 5% 10%;
}

.alert-success {
  color: green;
  background: #f4fef4;
  width: 70vw;
  font-size: 22px;
  padding: 6px 16px 6px 16px;
  margin: 5% 10% 5% 10%;
}

.add-crane-form {
  position: relative;
  margin-top: 8px;
}

.form-input-image {
  width: 85vw;
  padding: 10px 0;
  font-size: 16px;
  margin: 0 0 7% 0;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: start;
}

.form-input {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin: 0 0 7% 0;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: start;
}

label {
  text-align: left;
  float: left;
}

.label-div {
  font-size: 18px;
}

.slider-add-crane {
  width: 85vw;
  margin: 0 7.5% -1% 7.5%;
}

::-webkit-input-placeholder {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #000;
}

:-ms-input-placeholder {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #000;
}

::-ms-input-placeholder {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #000;
}

::placeholder {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #000;
}

.add-crane-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin: 10px 0 20px 0;

  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.add-crane-button:disabled {
  opacity: 40%;
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
}

.map-area p {
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  color: #f83600;
  width: 90vw;
  line-height: 35px;
  margin: 4% 0 4% 5%;
}

@media only screen and (min-width: 600px) {
  .add-crane-form {
    display: none;
  }
}

.search-add {
  position: relative;
  margin: 10px 0 -50px -4%;
  width: 100%;
  max-width: 250px;
}

.search-add input {
  padding: 6px;
  font-size: 16px;
  width: 70%;
}

.locate-add {
  margin: 10px 0 0 83%;
  background: none;
  border: none;
}

.locate-add img {
  width: 30px;
}

@media only screen and (min-width: 600px) {
  .map-area {
    display: none;
  }
}

.crane-card-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 40px;
}

.card-image-profile {
  object-fit: cover;
  width: 100vw;
}

.likes-profile {
  background-color: white;
  border-radius: 0 30px 0 0;
  padding: 0 10px 0 8px;
  position: relative;
  margin: -9% 0 0 -1.5%;
}

.basic-info-profile {
  margin: 0 0 2% 0;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
}

.basic-info-profile p {
  margin: 0 0 2% 0;
  padding-left: 20px;
  display: flex;
  text-align: start;
  color: black;
}

.show-more-button-profile {
  font-size: 18px;
  position: relative;
}
.extra-info-profile {
  text-align: start;
  width: 100vw;
}

.extra-info-items-profile {
  margin: -3% 4% -6% 5.5%;
}

.crane-distance-profile {
  color: #f83600;
  margin: 0 0 0 0;
}

.extra-info-profile button {
  margin: 10% 30% 7% 30%;
  text-align: center;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 16px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.like-icon-profile {
  font-size: 20px;
  color: #f83600;
  padding: 0 8px 0 6px;
}

@media only screen and (min-width: 600px) {
  .crane-card-profile {
    display: none;
  }
}

.cranes-added {
  margin: 5px 0 5px 0;
  font-size: 18px;
}

button {
  background: none;
  border: none;
}

@media only screen and (min-width: 600px) {
  .cranes-added {
    display: none;
  }
}

.register-p {
  font-size: 32px;
  position: relative;
  margin-top: 15px;
}

.register-form {
  position: relative;
}

.register-form input {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin-bottom: 44px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: left;
}

::-webkit-input-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

::-ms-input-placeholder {
  color: #000;
}

::placeholder {
  color: #000;
}

.register-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: -10px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.login-link {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.login-link:visited {
  color: #000;
  text-decoration: none;
}

.social-icons {
  width: 35px;
}

.social-links-register {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-left: -43px;
}

@media only screen and (min-width: 600px) {
  .register-p {
    display: none;
  }

  .register-form {
    display: none;
  }

  .login-link {
    display: none;
  }
}

.first-image {
  width: 100vw;
  position: relative;
  top: 0;
}

.cranger-p {
  font-size: 32px;
  text-align: center;
  position: relative;
  margin-top: 5px;
  margin: 0 5% 5% 5%;
}

.landing-page-p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  margin: 0 4% 0 4%;
}

.roy-squad-list {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: justify;
  list-style: none;
  padding: 0 10% 0 10%;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  padding: 0 28px 0 28px;
}

.landing-page-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.social-icons {
  width: 35px;
}

.social-links-landing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin: 25px 0 0 -43px;
}

@media only screen and (min-width: 600px) {
  .first-image {
    display: none;
  }

  .cranger-p {
    display: none;
  }

  .first-image {
    display: none;
  }

  .landing-page-p {
    display: none;
  }

  .roy-squad-list {
    display: none;
  }

  .landing-page-button {
    display: none;
  }

  .social-icons {
    display: none;
  }
}

.App {
  margin-top: 67px;
  margin-bottom: 67px;
  background: white;
  font-family: "Barlow", sans-serif;
  text-align: center;
  line-height: 45px;
  overflow-y: auto;
}

.logo-home {
  max-width: 70px;
  padding-top: 10px;
}

.crane-gif {
  padding-top: 20px;
  max-width: 95%;
}

.heading {
  font-size: 32px;
  position: relative;
  margin: 2% 0 0 0;
}

.settings-p {
  margin: 2% 0 0 0;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  color: #f83600;
}

.settings form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-label {
  line-height: 40px;
  font-size: 18px;
  font-weight: 500;
  margin: 2% 0 0 0;
}

.settings-form {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin: 0 0 7% 7.5%;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: start;
}

.settings-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin: 5% 0 0 7.5%;

  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

@media only screen and (min-width: 600px) {
  .heading {
    display: none;
  }

  .settings-p {
    display: none;
  }

  .settings form {
    display: none;
  }
}

.large-device {
  align-content: center;
}

.large-device-image {
  width: 50vw;
  padding-bottom: 5%;
}

.large-device p {
  margin: 0 5% 5% 5%;
  font-size: 28px;
  text-align: center;
}

.social-icons-large-device {
  width: 50px;
}

.social-links-large-device {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-left: -43px;
  padding-top: 5%;
}

@media only screen and (max-width: 599px) {
  .large-device {
    display: none;
  }

  .large-device-image {
    display: none;
  }

  .large-device p {
    display: none;
  }
}

@media only screen and (min-width: 1000px) {
  .large-device p {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1240px) {
  .large-device p {
    font-size: 36px;
  }
}


.alert-error {
  color: red;
  background: #ffe5e5;
  width: 70vw;
  font-size: 22px;
  padding: 6px 16px 6px 16px;
  margin: 5% 10% 5% 10%;
}

.alert-success {
  color: green;
  background: #f4fef4;
  width: 70vw;
  font-size: 22px;
  padding: 6px 16px 6px 16px;
  margin: 5% 10% 5% 10%;
}

.cranes-added {
  margin: 5px 0 5px 0;
  font-size: 18px;
}

button {
  background: none;
  border: none;
}

@media only screen and (min-width: 600px) {
  .cranes-added {
    display: none;
  }
}

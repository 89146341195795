.search-add {
  position: relative;
  margin: 10px 0 -50px -4%;
  width: 100%;
  max-width: 250px;
}

.search-add input {
  padding: 6px;
  font-size: 16px;
  width: 70%;
}

.locate-add {
  margin: 10px 0 0 83%;
  background: none;
  border: none;
}

.locate-add img {
  width: 30px;
}

@media only screen and (min-width: 600px) {
  .map-area {
    display: none;
  }
}

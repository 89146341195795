.header-items {
  display: flex;
  justify-content: center;
  list-style: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 45px;
  padding-bottom: 11px;
  padding-top: 8px;
  margin-top: 0px;
  margin-left: -35px;
  background-color: #f4f4f4;
  border-top: hidden;
  border-bottom: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
}

.header-icon {
  padding-right: 10px;
  height: 48px;
}

@media only screen and (min-width: 600px) {
  .header-items {
    display: none;
  }
}

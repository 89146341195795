.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 45px;
  padding-top: 15px;
  margin-left: -40px;
  margin-bottom: 0px;
  background-color: #f4f4f4;
  border-top: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
}

.navbar-icons {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 600px) {
  .navbar-links {
    display: none;
  }
}

.google-maps {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
}
.search {
  position: fixed;
  margin: 0 0 0 -3%;
  top: 80px;
  width: 100%;
  max-width: 250px;
  z-index: 10;
}

.search input {
  padding: 6px;
  font-size: 16px;
  width: 70%;
}

.locate {
  position: fixed;
  top: 85px;
  right: 0.5rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
}

@media only screen and (min-width: 600px) {
  .google-maps {
    display: none;
  }

  .search {
    display: none;
  }

  .locate {
    display: none;
  }
}

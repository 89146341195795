.first-image {
  width: 100vw;
  position: relative;
  top: 0;
}

.cranger-p {
  font-size: 32px;
  text-align: center;
  position: relative;
  margin-top: 5px;
  margin: 0 5% 5% 5%;
}

.landing-page-p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  margin: 0 4% 0 4%;
}

.roy-squad-list {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: justify;
  list-style: none;
  padding: 0 10% 0 10%;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  padding: 0 28px 0 28px;
}

.landing-page-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.social-icons {
  width: 35px;
}

.social-links-landing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin: 25px 0 0 -43px;
}

@media only screen and (min-width: 600px) {
  .first-image {
    display: none;
  }

  .cranger-p {
    display: none;
  }

  .first-image {
    display: none;
  }

  .landing-page-p {
    display: none;
  }

  .roy-squad-list {
    display: none;
  }

  .landing-page-button {
    display: none;
  }

  .social-icons {
    display: none;
  }
}

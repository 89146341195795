.popup-profile {
  position: absolute;
  background-color: white;
  border: 2px solid;
  border-image-source: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  border-image-slice: 1;
  height: 40%;
  width: 80%;
  margin: 0 0 0 4%;
  padding: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
}

.popup-profile:first-child {
  padding-top: 20px;
}

.popup-username {
  font-size: 30px;
  background: -webkit-linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 10px;
}

.popup-profile button {
  margin: 5% 0 5% 0;
  text-align: center;
  font-size: 16px;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

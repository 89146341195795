.register-p {
  font-size: 32px;
  position: relative;
  margin-top: 15px;
}

.register-form {
  position: relative;
}

.register-form input {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin-bottom: 44px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: left;
}

::placeholder {
  color: #000;
}

.register-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: -10px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.login-link {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.login-link:visited {
  color: #000;
  text-decoration: none;
}

.social-icons {
  width: 35px;
}

.social-links-register {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  margin-left: -43px;
}

@media only screen and (min-width: 600px) {
  .register-p {
    display: none;
  }

  .register-form {
    display: none;
  }

  .login-link {
    display: none;
  }
}

.add-crane-form {
  position: relative;
  margin-top: 8px;
}

.form-input-image {
  width: 85vw;
  padding: 10px 0;
  font-size: 16px;
  margin: 0 0 7% 0;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: start;
}

.form-input {
  width: 85vw;
  padding: 10px 0;
  font-size: 18px;
  margin: 0 0 7% 0;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  background: transparent;
  text-align: start;
}

label {
  text-align: left;
  float: left;
}

.label-div {
  font-size: 18px;
}

.slider-add-crane {
  width: 85vw;
  margin: 0 7.5% -1% 7.5%;
}

::placeholder {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #000;
}

.add-crane-button {
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin: 10px 0 20px 0;

  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.add-crane-button:disabled {
  opacity: 40%;
  width: 85vw;
  height: 50px;
  border-radius: 30px;
  border-style: none;
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
}

.map-area p {
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  color: #f83600;
  width: 90vw;
  line-height: 35px;
  margin: 4% 0 4% 5%;
}

@media only screen and (min-width: 600px) {
  .add-crane-form {
    display: none;
  }
}

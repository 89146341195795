.filter-sort {
  position: sticky;
  background-color: white;
  width: 100vw;
}

.filter-sort button {
  text-align: center;
  width: 40vw;
  height: 35px;
  border-radius: 30px;
  border-style: none;
  color: #fff;
  font-size: 20px;
  margin: 3% 3% 3% 3%;
  background: linear-gradient(
    45deg,
    rgba(248, 54, 0, 1),
    rgba(249, 212, 35, 1)
  );
}

.filter-sort ul {
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  margin-top: -10px;
  list-style: none;
}
.filter-sort li {
  flex: 1 0 200px;
  box-sizing: border-box;
  font-size: 16px;
}

.links {
  color: black;
  text-decoration: none;
}

.sliders {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10%;
  width: 80vw;
}
.filter-sort p {
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .filter-sort {
    display: none;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

.App {
  margin-top: 67px;
  margin-bottom: 67px;
  background: white;
  font-family: "Barlow", sans-serif;
  text-align: center;
  line-height: 45px;
  overflow-y: auto;
}

.logo-home {
  max-width: 70px;
  padding-top: 10px;
}

.crane-gif {
  padding-top: 20px;
  max-width: 95%;
}
